/*
For : SteamWhistle
*/
const SteamWhistle = {
    SERVER_URL: "https://analyticsmart-crm.b4a.app/",
    APP_ID: "4v8YIvpL8rMqphHAuOzDNuOmgDj5qJBcCvx3WOnG",
    JS_KEY: "rfDOFXsLQzuAony5o0DzoErynv4cyBXTu9SPjfyb",
    SITE_NAME: "SteamWhistle",
    DASHBOARDS: {
        OPPS: ['StoreOpportunityDashboards/TotalSummary', 'StoreOpportunityDashboards/Opps-Top5'],
        WORKFLOW: ['CRM-SteamwhistleWorkflowMockUp/WorkflowOverview'],
        REPORTS: ['CRM-SteamwhistleOntarioDemov2/StorePerformance', 'CRM-SteamwhistleOntarioDemov2/TerritoryPerformance']
    },
    GA_MEASUREMENT_ID: "G-556V6D896J"
}

/*
For : APL
*/
const APL = {
    SERVER_URL: "https://apl-crm.b4a.app/",
    APP_ID: "MpeIZPCoeYmWRKBipMW3gteySmrwDD20sBeO40Nr",
    JS_KEY: "YqIG7nt56ML3kR7pY3f4D1TTSsRTDtvuc1gvvSx6",
    SITE_NAME: "AndrewPeller",
    DASHBOARDS: {
        OPPS: ['StoreOpportunityDashboards/TotalSummary', 'StoreOpportunityDashboards/Opps-Top5', 'StoreOpportunityDashboards/AllOpportunities-FullList'],
        WORKFLOW: ['WorkflowMockUp/WorkflowOverview'],
        REPORTS: ['CRM-APLOntarioDemo/StorePerformance', 'CRM-APLOntarioDemo/TerritoryPerformance']
    },
    GA_MEASUREMENT_ID: ""
}

const AppConfig = SteamWhistle;
// const AppConfig = APL;


export { AppConfig };